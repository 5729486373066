<template>
  <div class="page">
    <div class="narrowBox">
      <div class="publish">
        <label
          class="publishT"
          v-if="type !== 'experience' && type !== 'education' && type !== 'my'"
          >{{ publishTitle }}</label
        >
        <label class="publishT" v-else>
          <i v-if="type === 'education'" class="el-icon--edu"></i>
          <i v-else-if="type === 'experience'" class="el-icon--dir"></i>
          <i v-else class="el-icon--userinfo"></i>
        </label>
        <el-form ref="pub_form" :rules="rules" :model="form">
          <el-form-item v-if="type === 'my'">
            <span class="ucid">ID:{{ form.uc_id }}</span>
            <el-radio-group v-model="form.gender">
              <el-radio label="1">
                <i class="el-icon--man gender"></i>
              </el-radio>
              <el-radio label="0">
                <i class="el-icon--woman gender"></i>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <div
            class="row-item"
            v-if="type !== 'experience' && type !== 'education'"
          >
            <el-form-item class="row" prop="title">
              <el-input
                v-model="form.title"
                :placeholder="
                  type === 'service'
                    ? $t('title')
                    : type === 'my'
                    ? $t('name')
                    : $t('called')
                "
              ></el-input>
            </el-form-item>
            <el-form-item
              class="price"
              v-if="type === 'service' || type == '1'"
            >
              <el-input
                v-model.trim="form.price"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item
            v-if="type === 'education'"
            :class="{ row: true, empty: schoolEmpty }"
            prop="school"
          >
            <el-input
              v-model="form.school"
              :placeholder="$t('school')"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="type === 'experience'"
            label
            :class="{ row: true, empty: schoolEmpty }"
            prop="company"
          >
            <el-input
              v-model="form.company"
              :placeholder="$t('companyName')"
            ></el-input>
          </el-form-item>
          <el-form-item class="editor">
            <quillEditor
              ref="myTextEditor"
              v-model="form.content"
              :options="editorOption"
            ></quillEditor>
          </el-form-item>
          <template v-if="type === 'experience' || type === 'education'">
            <div class="row-item">
              <el-form-item class="splice">
                <el-input
                  v-if="type === 'education'"
                  v-model="form.profess"
                  :placeholder="$t('profess')"
                ></el-input>
                <el-input
                  v-else
                  v-model="form.job"
                  :placeholder="$t('job')"
                ></el-input>
              </el-form-item>
              <el-form-item class="splice">
                <el-input
                  v-if="type === 'education'"
                  v-model="form.degree"
                  :placeholder="$t('degree')"
                ></el-input>
                <el-input
                  v-else
                  v-model="form.degree"
                  :placeholder="$t('address')"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item>
              <el-input
                v-model="form.time"
                :placeholder="$t('time')"
              ></el-input>
            </el-form-item>
          </template>
          <div class="row-item" v-if="type === 'my'">
            <el-form-item class="splice">
              <el-input
                v-model.trim="form.job"
                :placeholder="$t('job')"
              ></el-input>
            </el-form-item>
            <el-form-item class="splice">
              <i class="el-icon--location" />
              <el-cascader
                expand-trigger="hover"
                :options="countries"
                v-model="form.address"
                :show-all-levels="false"
                popper-class="myinfoCascader"
              ></el-cascader>
            </el-form-item>
          </div>
          <!-- prop="tags" tags 非必填 -->
          <el-form-item
            class="row tags"
            v-if="type !== 'experience' && type !== 'education'"
          >
            <template v-for="(tag, key) in form.tags">
              <el-tag
                :key="key"
                v-if="tag"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
                >{{ tag }}</el-tag
              >
            </template>
            <el-input
              class="input-new-tag"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              :class="{ tagerror: tagerror }"
              :placeholder="$t('tags')"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="categirys"
            v-if="type !== 'experience' && type !== 'education'"
            :class="{ cateEmp: errorStatus }"
          >
            <el-checkbox-group v-model="form.categirys" :max="3" size="small">
              <el-checkbox-button
                v-for="(cate, key) in categirys"
                :label="cate.category_id"
                :key="key"
                >{{ cate.category_name }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            class="uploads"
            v-if="
              type !== 'experience' && type !== 'education' && type !== 'my'
            "
          >
            <el-upload
              class="avatar-uploader"
              accept="image/*"
              list-type="picture-card"
              action="/"
              :multiple="true"
              :limit="maxLength"
              :file-list="form.imageData"
              :http-request="handlerUploadImg"
              :show-file-list="false"
              :before-upload="beforeUpload"
              v-if="form.imageData.length + form.videoData.length < maxLength"
            >
              <i class="el-icon-picture-outline"></i>
            </el-upload>
            <el-upload
              class="avatar-uploader"
              action="/"
              accept="video/*, audio/*"
              list-type="picture-card"
              :file-list="form.videoData"
              :show-file-list="false"
              :http-request="handlerUploadVideo"
              :before-upload="beforeUpload"
              v-if="
                form.imageData.length + form.videoData.length < 5 &&
                  type !== 'group'
              "
            >
              <i class="el-icon-video-camera"></i>
            </el-upload>
            <el-upload
              class="avatar-uploader"
              action="/"
              list-type="picture-card"
              :show-file-list="false"
              :http-request="handlerUploadFile"
              :before-upload="beforeUpload"
              v-if="!form.attachmentData.length && type !== 'group'"
            >
              <i class="el-icon-paperclip"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            class="img_VideoView"
            v-if="
              type !== 'experience' && type !== 'education' && type !== 'my'
            "
          >
            <ul>
              <li v-for="(item, key) in form.imageData" :key="key">
                <i
                  class="el-icon-remove"
                  @click="handlerRemoveItem('imageData', key)"
                ></i>
                <el-progress
                  type="circle"
                  :width="50"
                  :height="50"
                  v-if="item.progress !== '100.00'"
                  :percentage="parseInt(item.progress)"
                ></el-progress>
                <img :src="item.path" v-else-if="item.path" />
              </li>
              <li
                v-for="(item, index) in form.videoData"
                :key="
                  index ? form.imageData.length + index : form.imageData.length
                "
              >
                <i
                  class="el-icon-remove"
                  @click="handlerRemoveItem('videoData', index)"
                ></i>
                <el-progress
                  type="circle"
                  color="#33cc66"
                  :width="50"
                  :height="50"
                  v-if="item.progress !== '100.00'"
                  :percentage="parseInt(item.progress)"
                ></el-progress>
                <video :src="item.path" v-else-if="item.path" />
              </li>
            </ul>
            <ul class="files">
              <li v-for="(item, index) in form.attachmentData" :key="index">
                <i
                  class="el-icon-remove"
                  @click="handlerRemoveItem('attachmentData', index)"
                ></i>
                <el-progress
                  color="#33cc66"
                  v-if="item.progress !== '100.00'"
                  :percentage="parseInt(item.progress)"
                ></el-progress>
                <template v-else>
                  <i :class="getIconByType(item.name)" class="icon"></i>
                  <span>{{ item.name }}</span>
                </template>
              </li>
            </ul>
          </el-form-item>
          <el-form-item class="submit">
            <el-button
              type="primary"
              icon="el-icon-check"
              @click="
                id ? updateDetailFunc('pub_form') : handlerSubmit('pub_form')
              "
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <tips v-if="showTips" :show-tips="showTips"></tips>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { getUploadToken } from "@/api/qiniu";
import { publicDo } from "@/api/dado";
import * as qiniu from "qiniu-js";
import { env, parseTime } from "@/utils";
import tips from "@/components/business/web-site/dialog/tip";
import { getCategories } from "@/api/newVersion/pageHeader";
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
  ["blockquote"], // "code-block"
];
export default {
  name: "Publish",
  data() {
    return {
      inputVisible: false,
      errorStatus: false,
      inputValue: "",
      tagerror: false,
      form: {
        title: "",
        price: "",
        content: "",
        tags: [],
        roules: "0",
        categirys: [],
        files: [],
        imageData: [],
        videoData: [],
        attachmentData: [],
        company: "",
        school: "",
        profess: "",
        degree: "",
        address: "",
        job: "",
        time: "",
        uc_id: "",
        gender: ""
      },
      countries: [],
      categirys: [],
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          }
        },
        placeholder: "" //this.$t("contentTip")
      },
      rules: {
        title: [
          { required: true, trigger: "blur", message: " " } // this.$t("mustBeTM")
        ],
        tags: [
          { required: true, trigger: "blur", message: " " } //this.$t("mustBeTag")
        ],
        categirys: [
          { required: true, trigger: "blur", message: " " } // this.$t("mustBeCate")
        ],
        school: {
          required: true,
          trigger: "blur",
          message: " " //this.$t("mustSchool")
        },
        company: {
          required: true,
          trigger: "blur",
          message: " " //this.$t("mustCompany")
        }
      },
      language: 1,
      type: "",
      id: "",
      publishTitle: "",
      maxLength: 5,
      schoolEmpty: false,
      active: 0,
      showTips: false,
      fromRouter: {}
    };
  },
  components: {
    quillEditor,
    tips
  },
  computed: {
    watchcategirys() {
      return this.form.categirys;
    },
    watchSchool() {
      return this.form.school;
    },
    watchCompany() {
      return this.form.company;
    },
    watchFromRouter() {
      return this.$store.state.webStore.fromRouter;
    },
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    }
  },
  watch: {
    watchcategirys: {
      handler(val) {
        if (val && val.length) this.errorStatus = false;
      },
      dreep: true
    },
    watchSchool: {
      handler(val) {
        if (val) {
          this.schoolEmpty = false;
        }
      },
      dreep: true
    },
    watchFromRouter: {
      handler(val) {
        this.fromRouter = val;
      },
      dreep: true
    },
    watchCompany: {
      handler(val) {
        if (val) {
          this.schoolEmpty = false;
        }
      },
      dreep: true
    }
  },
  /**
   * this.type
   * service 发布需求
   * group 添加群组
   * !=='' || other 添加企业
   */
  async mounted() {
    this.$store.dispatch("commitMenuStatus", false);
    let langcode = window.localStorage.getItem("langCode");
    this.type = this.$route.params.type || "service";
    this.id =
      this.$route.params.id && this.$route.params.id.length > 4
        ? this.$Base64.decode(this.$route.params.id.replace("pai_", ""))
        : "";
    // this.active = this.$route.params.active;
    this.language = langcode ? parseInt(langcode) : 1;
    this.gettitleHandler();
    this.categirys = await this.getNewNavs();
    this.countries = await this.getCountrys();
    if (this.type === "group") this.maxLength = 1;
    if (this.id && this.id > 3) {
      this.getDetailFunc();
    }
  },
  methods: {
    changeRadio() {
      console.log(this.form.roules, "/form.roules");
    },
    async getCountrys() {
      let url = "public/data.do";
      let params = {
        funcId: "hex_helome_queryCountryByLanguageFunction",
        table_name_replace: localStorage.getItem("langCode") || "1",
        language_sign: 1
      };
      let result = await publicDo(params, url).catch(e => {
        console.log(e + "from src/views/publish.vue:498:16");
      });
      if (result.data && result.data.length) {
        return result.data.map(item => {
          return {
            value: item.nation_id,
            label: item.nation_name,
            sort: item.sort,
            children: item.countries.map(el => {
              return {
                value: el.country_code,
                label: el.country_name,
                sort: el.sort
              };
            })
          };
        });
      } else return [];

      //  self.countries = region;
    },
    gettitleHandler() {
      switch (this.type) {
        case "company":
          this.publishTitle = this.$t("company");
          break;
        case "experience":
          this.publishTitle = this.$t("workexp");
          break;
        case "education":
          this.publishTitle = this.$t("education");
          break;
      }
    },
    getDetailFunc() {
      switch (this.type) {
        case "service":
        case "1":
        case 1:
          this.getServicesDetail();
          break;
        case "group":
          this.getGroupDetail();
          break;
        case "company":
          this.getCompanyDetail();
          break;
        case "experience":
          this.getWrokExp();
          break;
        case "education":
          this.getEduinfo();
          break;
        case "my":
          this.getMyInfo();
          break;
      }
    },
    updateDetailFunc(formname) {
      this.$refs[formname].validate(valid => {
        if (valid) {
          switch (this.type) {
            case "service":
            case "1":
            case 1:
              this.updateServiceDetail();
              break;
            case "group":
              this.updateGroupDetail();
              break;
            case "company":
              this.updateCompanyDetail();
              break;
            case "my":
              this.updateMyInfo();
              break;
            case "education":
              if (!this.form.school) {
                this.schoolEmpty = !this.schoolEmpty;
                return;
              }
              this.updateEducation();
              break;
            case "experience":
              if (!this.form.company) {
                this.schoolEmpty = !this.schoolEmpty;
                return;
              }
              this.updateWrokExp();
              break;
          }
        } else {
          if (!this.form.categirys.length) this.errorStatus = true;
          return;
        }
      });
    },
    async updateGroupDetail() {
      let params = {
        providerId: this.id,
        userId: this.$store.getters.userInfo.id,
        language: this.language,
        groupId: this.id,
        groupName: this.form.title,
        categoryId: this.form.categirys.join(","),
        tags: this.form.tags.join(","),
        accessPattern: parseInt(this.form.roules) + 1,
        groupDetail: this.form.content,
        imageData: JSON.stringify(this.form.imageData),
        videoData: JSON.stringify(this.form.videoData),
        attachmentData: JSON.stringify(this.form.attachmentData[0])
      };

      let result = await this.$http({
        url: "rest/helogig/v3/group/" + this.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:622:16");
      });
      if (result) {
        this.updateSuccessFunc();
      }
    },
    async updateCompanyDetail() {
      let params = {
        language: this.language,
        providerId: this.id,
        userId: this.$store.getters.userInfo.id,
        enterpriseId: this.id,
        enterpriseName: this.form.title,
        categoryId: this.form.categirys.join(","),
        tags: this.form.tags.join(","),
        enterpriseInfo: this.form.content,
        imageData: JSON.stringify(this.form.imageData),
        videoData: JSON.stringify(this.form.videoData),
        attachmentData: JSON.stringify(this.form.attachmentData[0])
      };

      let result = await this.$http({
        url: "rest/helogig/v3/enterprise/" + this.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:646:16");
      });
      if (result) {
        this.updateSuccessFunc();
      }
    },
    async updateMyInfo() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        name: this.form.title,
        gender: this.form.gender,
        country:
          typeof this.form.address === "string"
            ? this.form.address
            : this.form.address[1],
        category: this.form.categirys.join(","),
        tags: this.form.tags.join(","),
        aboutMe: this.form.content,
        iCanDo: this.form.job,
        profilePic: this.form.profile_photo
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/baseInfo/" + this.$store.getters.userInfo.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:672:16");
      });
      if (result.code == 1) {
        this.updateSuccessFunc();
      } else {
        this.$message({
          type: "error",
          message: result.message
        })
      }
    },

    async updateEducation() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        educationId: this.id,
        school: this.form.school,
        degree: this.form.degree,
        studyField: this.form.profess,
        time: this.form.time,
        description: this.form.content.replace(/<\/?.+?\/?>/g, "")
      };
      let result = await this.$http({
        url: "/rest/personalCenter/v3/education/" + this.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:694:16");
      });
      if (result) {
        this.updateSuccessFunc();
      }
    },

    async updateWrokExp() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        experienceId: this.id,
        company: this.form.company,
        title: this.form.job,
        location: this.form.degree,
        time: this.form.time,
        description: this.form.content.replace(/<\/?.+?\/?>/g, "")
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/experience/" + this.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:716:16");
      });
      if (result) {
        this.updateSuccessFunc();
      }
    },
    updateSuccessFunc() {
      this.form.content = this.form.price = "";
      this.form.imageData = this.form.videoData = this.form.attachmentData = [];
      this.showTipsChange();
    },
    async updateServiceDetail() {
      let params = {
        language: this.language,
        helogigId: this.id,
        userId: this.$store.getters.userInfo.id,
        iCanDo: this.form.title,
        categoryId: this.form.categirys.join(","),
        tags: this.form.tags.join(","),
        price: this.form.price,
        detail: this.form.content,
        imageData: JSON.stringify(this.form.imageData),
        videoData: JSON.stringify(this.form.videoData),
        attachmentData: JSON.stringify(this.form.attachmentData[0])
      };

      let result = await this.$http({
        url: "rest/helogig/v3/helogig/" + this.id,
        data: params,
        method: "put",
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:757:16");
      });
      if (result) {
        this.updateSuccessFunc();
      }
    },
    async getServicesDetail() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        type: 0,
        id: this.id
      };
      let result = await this.$http({
        url: "rest/detail/v3/getServiceJobDetail",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:771:16");
      });
      if (result) {
        this.form = this.formartService(result.data[0]);
      }
    },

    async getWrokExp() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        providerId: this.$store.getters.userInfo.id,
        experienceId: this.id
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/experience/detail",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:788:16");
      });
      if (result) {
        this.form = this.formartWrokExp(result.data.experience);
      }
    },
    async getEduinfo() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        providerId: this.$store.getters.userInfo.id,
        educationId: this.id
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/education/detail",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:804:16");
      });
      if (result) {
        this.form = this.formartEduinfo(result.data.education);
      }
    },

    async getMyInfo() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        providerId: this.id
      };
      let result = await this.$http({
        url: "rest/personalCenter/v3/getProfileInfoData",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:820:16");
      });
      if (result) {
        // 这里可能出现在类型列表中找不到已设置的类型，所以要过滤一下
        this.form = this.formartMy(result.data[0]);
        if(this.form.categirys !== 0 && this.categirys.length !== 0) {
          let categirys = [];
          this.form.categirys.forEach( element => {
            let isInLocal = this.categirys.some( item => {
              return item.category_id.toString() === element 
            })
            // 如果在本地存储中没有找到已设置过的类型，则默认删除已设置的类型
            if(isInLocal) {
              categirys.push(element.toString())
            }
          })
          this.form.categirys = categirys
        }
      }
    },
    async getGroupDetail() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        groupId: this.id
      };
      let result = await this.$http({
        url: "rest/detail/v3/getGroupDetail",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:841:16");
      });
      if (result) {
        this.form = this.formartGroup(result.data[0]);
      }
    },
    async getCompanyDetail() {
      let params = {
        language: this.language,
        userId: this.$store.getters.userInfo.id,
        enterpriseId: this.id
      };
      let result = await this.$http({
        url: "rest/detail/v3/getEnterpriseDetail",
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:850:16");
      });
      if (result) {
        this.form = this.formartCompany(result.data[0]);
      }
    },
    isChecked(id) {
      return this.form.categirys.some(item => {
        return item == id;
      });
    },
    formartMy(data) {
      let cates = [];
      if (data.categories) {
        data.categories.split(",").map(el => {
          if (parseInt(el) !== "NaN") {
            cates.push(parseInt(el).toString());
          }
        });
      }
      cates = cates.filter(item => this.categirys.some(element => element.category_id == item));
      let _returnData = {
        uc_id: data.uc_id,
        gender: data.gender,
        title: data.name,
        price: 0,
        content: data.about_me,
        address: data.country,
        job: data.i_can_do,
        profile_photo: data.profile_photo,
        tags:
          data.skills.indexOf(",") !== -1
            ? data.skills.split(",")
            : data.skills
            ? [data.skills]
            : [],
        roules: 1,
        categirys: cates,
        files: [],
        imageData: [],
        videoData: [],
        attachmentData: []
      };
      return _returnData;
    },
    formartEduinfo(data) {
      return {
        school: data.school,
        profess: data.fieldOfStudy,
        content: data.description,
        degree: data.degreeStr,
        time: data.time
      };
    },
    formartWrokExp(data) {
      return {
        company: data.companyName,
        job: data.title,
        degree: data.location,
        content: data.description,
        time: data.time

        // params.company = this.form.company;
        //       params.title = this.form.job;
        //       params.location = this.form.address;
        //       params.time = this.form.time;
        //       params.description = this.form.content;
      };
    },
    formartGroup(data) {
      let cates = [];
      if (data.group_category) {
        data.group_category.split(",").map(el => {
          if (parseInt(el) !== "NaN") {
            cates.push(parseInt(el).toString());
          }
        });
      }
      cates = cates.filter(item => this.categirys.some(element => element.category_id == item));
      return {
        title: data.group_name,
        price: 0,
        content: data.group_info,
        tags:
          data.group_tag.indexOf(",") !== -1
            ? data.group_tag.split(",")
            : data.group_tag
            ? [data.group_tag]
            : [],
        roules: (parseInt(data.access_pattern) - 1).toString(),
        categirys: cates,
        files: [],
        imageData: this.fliterMediadata(data.mediaDatas, null, null),
        videoData: this.fliterMediadata(data.mediaDatas, false, true),
        attachmentData: this.fliterMediadata(data.mediaDatas, true, null)
      };
    },
    formartCompany(data) {
      let cates = [];
      if (data.enterprise_category) {
        data.enterprise_category.split(",").map(el => {
          if (parseInt(el) !== "NaN") {
            cates.push(parseInt(el).toString());
          }
        });
      }
      cates = cates.filter(item => this.categirys.some(element => element.category_id == item));
      return {
        title: data.enterprise_name,
        price: 0,
        content: data.enterprise_info,
        tags:
          data.enterprise_tag.indexOf(",") !== -1
            ? data.enterprise_tag.split(",")
            : data.enterprise_tag
            ? [data.enterprise_tag]
            : [],
        roules: 1,
        categirys: cates,
        files: [],
        imageData: this.fliterMediadata(data.mediaDatas, null, null),
        videoData: this.fliterMediadata(data.mediaDatas, false, true),
        attachmentData: this.fliterMediadata(data.mediaDatas, true, null)
      };
    },
    formartService(data) {
      let cates = [];
      if (data.category_id) {
        data.category_id.split(",").map(el => {
          if (parseInt(el) !== "NaN") {
            cates.push(parseInt(el).toString());
          }
        });
      }
      cates = cates.filter(item => this.categirys.some(element => element.category_id == item));
      let formData = {
        title: data.i_can_do,
        price: data.price,
        content: data.detail,
        tags:
          data.tags.indexOf(",") !== -1
            ? data.tags.split(",")
            : data.tags
            ? [data.tags]
            : [],
        roules: 1,
        categirys: cates,
        files: [],
        imageData: this.fliterMediadata(data.mediaDatas, null, null),
        videoData: this.fliterMediadata(data.mediaDatas, false, true),
        attachmentData: this.fliterMediadata(data.mediaDatas, true, null)
      };
      // console.log(formData, "////");
      return formData;
    },
    fliterMediadata(arr, getFile, video) {
      let newArr = [];
      arr.forEach(element => {
        element.progress = "100.00";
        if (getFile) {
          if (element.type === 4) {
            newArr.push(element);
          }
        } else if (video) {
          if (element.type === 1) newArr.push(element);
        } else if (element.type !== 4 && element.type !== 1) {
          newArr.push(element);
        }
      });
      // console.log(newArr, "/newArr");
      return newArr;
    },
    handlerRemoveItem(field, index) {
      this.form[field].splice(index, 1);
    },
    async handlerUploadImg(files) {
      this.handlerUploadFileToQiniu(files).then((item)=>{
        this.form.imageData.push(item);
      });
    },
    async handlerUploadVideo(files) {
      this.handlerUploadFileToQiniu(files).then((item)=>{
        this.form.videoData.push(item);
      });
    },
    async handlerUploadFile(files) {
      this.handlerUploadFileToQiniu(files).then((item)=>{
        this.form.attachmentData.push(item);
      });
    },
    handlerUploadFileToQiniu(files) {
      return new Promise( async(resolve, reject) => {
        let file = files.file;
        let oldName = file.name;
        let type = file.type;

        const isvideo = ["audio", "video"];
        const bucket =
          process.env.NODE_ENV == "development"
            ? "tfile"
            : isvideo.some(val => file.type.indexOf(val) !== -1)
            ? "video"
            : "imfile";
        const config = await getUploadToken(file.name, bucket);
        const { randomFileName, token } = config.data;
        const [year, month] = parseTime(new Date(), "{y}-{m}").split("-");
        const observable = qiniu.upload(
          file,
          `${year}/${month}/${randomFileName}`,
          token,
          {
            fname: file.name
          },
          {
            useCdnDomain: true,
            region: qiniu.region.z2
          }
        );
        let item = {
          name: oldName,
          type,
          progress: 0,
          path: "",
          size: file.size,
          isUploading:true
        };
        var subscription = observable.subscribe({
          next: uploadEvent => {
            item.progress = uploadEvent.total.percent.toFixed(2);
          },
          error: error => {
            console.log("error", error);
          },
          complete: res => {
            const baseurl = {
              tfile: env("UPLOAD_IMFILE"),
              video: env("UPLOAD_VIDEO"),
              imfile: env("UPLOAD_IMFILE")
            };
            item.path = `${baseurl[bucket]}/${res.key}`;
            item.isUploading = false;
          }
        });
        // 这里写在外面是因为上传视频要展示上传进度，得先展示出来
        resolve(item);
      })
    },
    beforeUpload() {
      // console.log(file, "/before upload");
    },
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
    },
    handlerSubmit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          let params = {
            language: this.language,
            userId: this.$store.getters.userInfo.id
          };
          if (this.type !== "education" || this.type !== "experience") {
            (params.categoryId = this.form.categirys.join(",")),
              (params.tags = this.form.tags.join(","));
          }
          switch (this.type) {
            case "service":
            case "1":
            case 1:
              params.price = this.form.price;
              params.iCanDo = this.form.title;
              params.detail = this.form.content;
              break;
            case "group":
              params.groupName = this.form.title;
              params.accessPattern = parseInt(this.form.roules) + 1;
              params.groupDetail = this.form.content;
              break;
            case "company":
              params.enterpriseName = this.form.title;
              params.enterpriseInfo = this.form.content;
              break;
            case "education":
              params.school = this.form.school;
              params.degree = this.form.degree;
              params.studyField = this.form.profess;
              params.time = this.form.time;
              params.description = this.form.content.replace(
                /<\/?.+?\/?>/g,
                ""
              );
              break;
            case "experience":
              params.company = this.form.company;
              params.title = this.form.job;
              params.location = this.form.degree;
              params.time = this.form.time;
              params.description = this.form.content.replace(
                /<\/?.+?\/?>/g,
                ""
              );
              // console.log(this.form,'/foprm')
              break;
          }
          let isUploading = false;
          if (this.form.imageData.length){
            isUploading = this.form.imageData.some( e => e.isUploading === true);
            params.imageData = JSON.stringify(this.form.imageData);
          }
          if (this.form.videoData.length){
            isUploading = this.form.videoData.some( e => e.isUploading === true);
            params.videoData = JSON.stringify(this.form.videoData);
          }
          if (this.form.attachmentData.length){
            isUploading = this.form.attachmentData.some( e => e.isUploading === true);
            params.attachmentData = JSON.stringify(this.form.attachmentData[0]);
          }
          if(isUploading){
            return;
          }
          switch (this.type) {
            case "service":
              this.handlerPublish(params, "rest/helogig/v3/helogig");
              break;
            case "group":
              this.handlerPublish(params, "rest/helogig/v3/group");
              break;
            case "company":
              this.handlerPublish(params, "rest/helogig/v3/enterprise");
              break;
            case "education":
              if (!params.school) {
                this.schoolEmpty = !this.schoolEmpty;
                return;
              }
              this.handlerPublish(params, "rest/personalCenter/v3/education");
              break;
            case "experience":
              if (!params.company) {
                this.schoolEmpty = !this.schoolEmpty;
                return;
              }
              this.handlerPublish(params, "rest/personalCenter/v3/experience");
              break;
          }
        } else {
          if (!this.form.categirys.length) this.errorStatus = true;
          return;
        }
      });
    },
    showTipsChange() {
      this.showTips = !this.showTips;
      setTimeout(() => {
        this.showTips = !this.showTips;
        try{
          this.$refs["pub_form"].resetFields();
        }catch(e){}
        // 跳转首页
        this.routeJump({ name: "home" });
      }, 500);
    },
    async handlerPublish(params, url) {
      let result = await this.$http({
        url,
        type: "jason",
        method: "post",
        data: params,
        withCredentials: false
      }).catch(e => {
        console.log(e + "from src/views/publish.vue:1196:16");
      });
      if (result) {
        this.showTipsChange();
      } else {
      }
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      // if (this.form.tags.indexOf(inputValue) !== -1) {
      //   this.tagerror = true
      //   // this.$message({
      //   //   type: "error",
      //   //   message: `tag "${inputValue}" is already exists`
      //   // });
      // } else if (inputValue) {
      //   this.tagerror = false
      if (inputValue) this.form.tags.push(inputValue);
      // }
      this.inputValue = "";
    },
    async getNewNavs() {
      let result = await getCategories();
      return result.data.data;
    }
  }
};
</script>
<style lang="stylus">
.tagerror {
  input {
    border-color: red !important;
  }
}

.ql-container, .ql-editor {
  min-height: 400px;
  font-family: 'Microsoft YaHei', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  max-height: 600px;
  overflow-y: scroll;
  // background: #ffffff;
}

.ql-snow {
  border-color: #DCDFE6 !important;
}

.publish {
  flex: 1;
  display: flex;
  flex-flow: column;
  background: #ffffff;
  padding: 0.9rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 6px 3px #efefef;
  max-width: 100%;

  .gender {
    font-size: 18px;

    &:before {
    }
  }

  .ucid {
    margin: 0 0.9rem 0 0;
  }

  // border: 1px solid #DCDFE6;
  .publishT {
    text-align: center;
    padding: 0 0.9rem 1.5rem;

    i {
      font-size: 22px;
      color: #666666;
    }
  }

  .el-form {
    .row-item {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      .splice {
        flex: 1;

        &:nth-child(1) {
          margin-right: 0.9rem;
        }

        .el-form-item__content {
          display: flex;
          flex-flow: row;
          align-items: center;
          position: relative;

          .el-cascader {
            flex: 1;

            .el-input {
              input {
                padding-left: 8%;
              }

              .el-input__suffix {
                right: 50px;
              }
            }
          }

          i {
            margin: 0 0.5rem;
            font-size: 20px;
            position: absolute;
            left: 5px;
            z-index: 10;
          }
        }
      }

      .row {
        flex: 3;
      }

      .price {
        flex: 1;
        margin-left: 0.9rem;
      }
    }

    .el-form-item {
      &.is-error {
        .el-input__inner {
          border-width: 2px;
        }
      }

      &.cateEmp {
        border: 2px solid red;
        border-radius: 4px;
      }

      &.empty {
        .el-input__inner {
          border: 2px solid red;
        }
      }

      &.img_VideoView {
        .el-form-item__content {
          ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;

            &.files {
              flex-flow: column;
              margin-top: 0.5rem;
              border-top: 1px solid #eeeeee;
              padding: 0.8rem 0;

              li {
                border: 0;
                width: max-content;
                background: none;
                height: auto;
                display: inline-block;
                padding: 0.25rem 0;
                margin: 0 0.5rem 0.5rem 0;
                min-width: 120px;
                min-height: 60px;

                i {
                  top: 0;
                  right: -0.5rem;

                  &.icon {
                    position: relative;
                    color: #33cc66;
                    font-size: 2.5rem;
                    display: inline-block;
                    margin-right: 0.9rem;
                  }
                }
              }
            }

            li {
              width: 120px;
              height: 100px;
              background: #eeeeee;
              margin: 0 0.5rem 0.25rem;
              border: 1px solid #e5e5e5;
              border-radius: 0.2rem;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                position: absolute;
                right: -10px;
                top: -8px;
                font-size: 18px;
                color: #f00;
                cursor: pointer;
                z-index: 1000;
              }

              video {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }

              img {
                width: 120px;
                height: 100px;
                object-fit: cover;
              }
            }
          }
        }
      }

      &.uploads {
        .el-form-item__content {
          display: flex;
          flex-flow: row;

          .avatar-uploader {
            .el-upload {
              width: 50px;
              height: 50px;
              line-height: 60px;
              margin: 0 0.35rem 0;
            }
          }
        }
      }

      &.cloumn {
        .el-form-item__content {
          .el-radio-group {
            display: flex;
            flex-flow: column;

            label {
              margin: 0.7rem 0;

              span {
                font-size: 13px;
                // color: #999999;
              }
            }
          }
        }
      }

      &.submit {
        .el-form-item__content {
          display: flex;
          justify-content: center;

          .el-button {
            border-radius: 2rem;
            min-width: 200px;
            padding: 8px 15px;

            i {
              font-size: 1.5rem;
            }
          }
        }
      }

      &.editor {
        .el-form-item__content {
          line-height: 20px !important;
        }
        .ql-container {
          overflow: hidden
          .ql-editor {
            overflow-x: hidden
            overflow-y auto;
            &::-webkit-scrollbar {
              width 2px;
            }
          }
        }
      }

      &.row {
        &.tags {
          border: 1px solid #DCDFE6;
          align-items: center;
          .el-form-item__content {
            padding: 0.5rem;
          }
        }

        .el-form-item__content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .el-input {
            &:nth-child(1) {
              flex: 3;
            }

            &:nth-child(2) {
              margin-left: 0.9rem;
              flex: 1;
            }
          }

          .el-tag {
            margin: 0 0.5rem 0 0;
            border-radius: 2rem;
            position: relative;
            padding: 0 0.98rem;

            i {
              position: absolute;
              background: #ebfaf0;
              border-color: #d6f5e0;

              &:hover {
                background: #33cc66;
              }
            }
          }

          .input-new-tag {
            max-width: 80px !important;

            // margin: 0 0.5rem 0.5rem 0;
            input {
              border-radius: 2rem;
              margin: 0;
              padding: 0;
              height: 30px;
              line-height: 30px;
              text-indent: 0.5rem;
            }
          }
        }
      }

      .el-form-item__content {
        .el-checkbox-group {
          .el-checkbox-button {
            margin: 0 0.25rem 0.25rem;

            .el-checkbox-button__inner {
              border: 1px solid #eeeeee;
              border-radius: 0.3rem;
            }

            &.is-checked {
              .el-checkbox-button__inner {
                border: 1px solid #33cc66;
              }
            }
          }
        }
      }
    }
  }
}
</style>
