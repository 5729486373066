var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "narrowBox" }, [
        _c(
          "div",
          { staticClass: "publish" },
          [
            _vm.type !== "experience" &&
            _vm.type !== "education" &&
            _vm.type !== "my"
              ? _c("label", { staticClass: "publishT" }, [
                  _vm._v(_vm._s(_vm.publishTitle))
                ])
              : _c("label", { staticClass: "publishT" }, [
                  _vm.type === "education"
                    ? _c("i", { staticClass: "el-icon--edu" })
                    : _vm.type === "experience"
                    ? _c("i", { staticClass: "el-icon--dir" })
                    : _c("i", { staticClass: "el-icon--userinfo" })
                ]),
            _c(
              "el-form",
              { ref: "pub_form", attrs: { rules: _vm.rules, model: _vm.form } },
              [
                _vm.type === "my"
                  ? _c(
                      "el-form-item",
                      [
                        _c("span", { staticClass: "ucid" }, [
                          _vm._v("ID:" + _vm._s(_vm.form.uc_id))
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.gender,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "gender", $$v)
                              },
                              expression: "form.gender"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _c("i", { staticClass: "el-icon--man gender" })
                            ]),
                            _c("el-radio", { attrs: { label: "0" } }, [
                              _c("i", { staticClass: "el-icon--woman gender" })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type !== "experience" && _vm.type !== "education"
                  ? _c(
                      "div",
                      { staticClass: "row-item" },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "row", attrs: { prop: "title" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  _vm.type === "service"
                                    ? _vm.$t("title")
                                    : _vm.type === "my"
                                    ? _vm.$t("name")
                                    : _vm.$t("called")
                              },
                              model: {
                                value: _vm.form.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "title", $$v)
                                },
                                expression: "form.title"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.type === "service" || _vm.type == "1"
                          ? _c(
                              "el-form-item",
                              { staticClass: "price" },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.price,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "price",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.price"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "education"
                  ? _c(
                      "el-form-item",
                      {
                        class: { row: true, empty: _vm.schoolEmpty },
                        attrs: { prop: "school" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: _vm.$t("school") },
                          model: {
                            value: _vm.form.school,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "school", $$v)
                            },
                            expression: "form.school"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "experience"
                  ? _c(
                      "el-form-item",
                      {
                        class: { row: true, empty: _vm.schoolEmpty },
                        attrs: { label: "", prop: "company" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: _vm.$t("companyName") },
                          model: {
                            value: _vm.form.company,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "company", $$v)
                            },
                            expression: "form.company"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { staticClass: "editor" },
                  [
                    _c("quillEditor", {
                      ref: "myTextEditor",
                      attrs: { options: _vm.editorOption },
                      model: {
                        value: _vm.form.content,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "content", $$v)
                        },
                        expression: "form.content"
                      }
                    })
                  ],
                  1
                ),
                _vm.type === "experience" || _vm.type === "education"
                  ? [
                      _c(
                        "div",
                        { staticClass: "row-item" },
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "splice" },
                            [
                              _vm.type === "education"
                                ? _c("el-input", {
                                    attrs: { placeholder: _vm.$t("profess") },
                                    model: {
                                      value: _vm.form.profess,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "profess", $$v)
                                      },
                                      expression: "form.profess"
                                    }
                                  })
                                : _c("el-input", {
                                    attrs: { placeholder: _vm.$t("job") },
                                    model: {
                                      value: _vm.form.job,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "job", $$v)
                                      },
                                      expression: "form.job"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { staticClass: "splice" },
                            [
                              _vm.type === "education"
                                ? _c("el-input", {
                                    attrs: { placeholder: _vm.$t("degree") },
                                    model: {
                                      value: _vm.form.degree,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "degree", $$v)
                                      },
                                      expression: "form.degree"
                                    }
                                  })
                                : _c("el-input", {
                                    attrs: { placeholder: _vm.$t("address") },
                                    model: {
                                      value: _vm.form.degree,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "degree", $$v)
                                      },
                                      expression: "form.degree"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: { placeholder: _vm.$t("time") },
                            model: {
                              value: _vm.form.time,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "time", $$v)
                              },
                              expression: "form.time"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.type === "my"
                  ? _c(
                      "div",
                      { staticClass: "row-item" },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "splice" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: _vm.$t("job") },
                              model: {
                                value: _vm.form.job,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "job",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.job"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "splice" },
                          [
                            _c("i", { staticClass: "el-icon--location" }),
                            _c("el-cascader", {
                              attrs: {
                                "expand-trigger": "hover",
                                options: _vm.countries,
                                "show-all-levels": false,
                                "popper-class": "myinfoCascader"
                              },
                              model: {
                                value: _vm.form.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "address", $$v)
                                },
                                expression: "form.address"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type !== "experience" && _vm.type !== "education"
                  ? _c(
                      "el-form-item",
                      { staticClass: "row tags" },
                      [
                        _vm._l(_vm.form.tags, function(tag, key) {
                          return [
                            tag
                              ? _c(
                                  "el-tag",
                                  {
                                    key: key,
                                    attrs: {
                                      closable: "",
                                      "disable-transitions": false
                                    },
                                    on: {
                                      close: function($event) {
                                        return _vm.handleClose(tag)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag))]
                                )
                              : _vm._e()
                          ]
                        }),
                        _c("el-input", {
                          ref: "saveTagInput",
                          staticClass: "input-new-tag",
                          class: { tagerror: _vm.tagerror },
                          attrs: { size: "small", placeholder: _vm.$t("tags") },
                          on: { blur: _vm.handleInputConfirm },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleInputConfirm.apply(
                                null,
                                arguments
                              )
                            }
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue"
                          }
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.type !== "experience" && _vm.type !== "education"
                  ? _c(
                      "el-form-item",
                      {
                        class: { cateEmp: _vm.errorStatus },
                        attrs: { prop: "categirys" }
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { max: 3, size: "small" },
                            model: {
                              value: _vm.form.categirys,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "categirys", $$v)
                              },
                              expression: "form.categirys"
                            }
                          },
                          _vm._l(_vm.categirys, function(cate, key) {
                            return _c(
                              "el-checkbox-button",
                              { key: key, attrs: { label: cate.category_id } },
                              [_vm._v(_vm._s(cate.category_name))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type !== "experience" &&
                _vm.type !== "education" &&
                _vm.type !== "my"
                  ? _c(
                      "el-form-item",
                      { staticClass: "uploads" },
                      [
                        _vm.form.imageData.length + _vm.form.videoData.length <
                        _vm.maxLength
                          ? _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action: "/",
                                  multiple: true,
                                  limit: _vm.maxLength,
                                  "file-list": _vm.form.imageData,
                                  "http-request": _vm.handlerUploadImg,
                                  "show-file-list": false,
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm.form.imageData.length + _vm.form.videoData.length <
                          5 && _vm.type !== "group"
                          ? _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: "/",
                                  accept: "video/*, audio/*",
                                  "list-type": "picture-card",
                                  "file-list": _vm.form.videoData,
                                  "show-file-list": false,
                                  "http-request": _vm.handlerUploadVideo,
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              [_c("i", { staticClass: "el-icon-video-camera" })]
                            )
                          : _vm._e(),
                        !_vm.form.attachmentData.length && _vm.type !== "group"
                          ? _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: "/",
                                  "list-type": "picture-card",
                                  "show-file-list": false,
                                  "http-request": _vm.handlerUploadFile,
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              [_c("i", { staticClass: "el-icon-paperclip" })]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type !== "experience" &&
                _vm.type !== "education" &&
                _vm.type !== "my"
                  ? _c("el-form-item", { staticClass: "img_VideoView" }, [
                      _c(
                        "ul",
                        [
                          _vm._l(_vm.form.imageData, function(item, key) {
                            return _c(
                              "li",
                              { key: key },
                              [
                                _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.handlerRemoveItem(
                                        "imageData",
                                        key
                                      )
                                    }
                                  }
                                }),
                                item.progress !== "100.00"
                                  ? _c("el-progress", {
                                      attrs: {
                                        type: "circle",
                                        width: 50,
                                        height: 50,
                                        percentage: parseInt(item.progress)
                                      }
                                    })
                                  : item.path
                                  ? _c("img", { attrs: { src: item.path } })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _vm._l(_vm.form.videoData, function(item, index) {
                            return _c(
                              "li",
                              {
                                key: index
                                  ? _vm.form.imageData.length + index
                                  : _vm.form.imageData.length
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function($event) {
                                      return _vm.handlerRemoveItem(
                                        "videoData",
                                        index
                                      )
                                    }
                                  }
                                }),
                                item.progress !== "100.00"
                                  ? _c("el-progress", {
                                      attrs: {
                                        type: "circle",
                                        color: "#33cc66",
                                        width: 50,
                                        height: 50,
                                        percentage: parseInt(item.progress)
                                      }
                                    })
                                  : item.path
                                  ? _c("video", { attrs: { src: item.path } })
                                  : _vm._e()
                              ],
                              1
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "ul",
                        { staticClass: "files" },
                        _vm._l(_vm.form.attachmentData, function(item, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c("i", {
                                staticClass: "el-icon-remove",
                                on: {
                                  click: function($event) {
                                    return _vm.handlerRemoveItem(
                                      "attachmentData",
                                      index
                                    )
                                  }
                                }
                              }),
                              item.progress !== "100.00"
                                ? _c("el-progress", {
                                    attrs: {
                                      color: "#33cc66",
                                      percentage: parseInt(item.progress)
                                    }
                                  })
                                : [
                                    _c("i", {
                                      staticClass: "icon",
                                      class: _vm.getIconByType(item.name)
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.name))])
                                  ]
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { staticClass: "submit" },
                  [
                    _c("el-button", {
                      attrs: { type: "primary", icon: "el-icon-check" },
                      on: {
                        click: function($event) {
                          _vm.id
                            ? _vm.updateDetailFunc("pub_form")
                            : _vm.handlerSubmit("pub_form")
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      ]),
      _vm.showTips
        ? _c("tips", { attrs: { "show-tips": _vm.showTips } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }